<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mb-5">
          <v-card-title>
            <strong>{{ post.title }}</strong>
          </v-card-title>
          <v-card-subtitle v-if="post.account">{{ post.account.username }} {{ formatDateTime(post.addTime) }}</v-card-subtitle>
          <v-card-text id="post-content" v-html="post.content"></v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="prepareReplyToPost">回复</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-for="(reply, index) in post.replies" :key="reply.id" class="mb-5">
          <v-card-title>
            <div>
              {{ reply.account.username }} {{ formatDateTime(reply.addTime) }}
            </div>
          </v-card-title>
          <v-card-text class="pre-line">
          <div v-if="reply.referencedReply" class="referenced-reply">
            <p class="referenced-reply-author">引用 {{ reply.referencedReply.account.username }} 于 {{ formatDateTime(reply.referencedReply.addTime) }} 的回复：</p>
            <p class="referenced-reply-content" v-html="reply.referencedReply.content"></p>
          </div>
            <div v-html="reply.content"></div>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" @click="prepareReply(reply.id)">回复</v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
    <!-- 回复对话框 -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">回复</v-card-title>
        <v-card-text>
          <quill-editor ref="editor" v-model="newReply" :options="editorOptions"></quill-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="submitReply">提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import service from '@/utils/request';

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  name: "ForumPost",
  components: {
    quillEditor
  },
  inject: ['showMessage'],
  data() {
    return {
      post: {},
      replies: [],
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体大小
              ['bold', 'italic', 'underline', 'strike'],        // 加粗、斜体、下划线、删除线
              [{ 'color': [] }, { 'background': [] }],          // 字体颜色、背景颜色
              ['link'],                                         // 插入链接
              ['blockquote', 'code-block'],                     // 引用、代码块
              ['image'],                                        // 添加图片按钮
            ],
            handlers: {
              'image': this.imageHandler                         // 图片处理函数
            }
          },
        },
        placeholder: "",
        theme: 'snow'  // 主题
      },
      dialog: false, // 控制对话框的显示
      referencedReplyId: null, // 添加用于存储被引用回复ID的数据字段
      newReply: ''
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchPost();
  },
  methods: {
    formatDateTime(dateTimeStr) {
      const date = new Date(dateTimeStr);
      return date.toLocaleString();
    },
    fetchPost() {
      service.get(`/wikiapi/forum/posts/${this.id}`)
        .then(response => {
          this.post = response.data;
          this.replies = response.data.replies;
        })
        .catch(error => {
          this.showMessage(error.response.data.message, 'error');
        });
    },
    prepareReplyToPost() {
      this.referencedReplyId = null;
      this.dialog = true; // 打开对话框
      this.$nextTick(() => {
        this.setFocusToEditor();
      });
    },
    prepareReply(replyId) {
      this.referencedReplyId = replyId;
      this.dialog = true; // 打开对话框
      this.$nextTick(() => {
        this.setFocusToEditor();
      });
    },
    setFocusToEditor() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.editor && this.$refs.editor.quill) {
            this.$refs.editor.quill.focus();
          }
        }, 300); // 延迟300毫秒
      });
    },
    submitReply() {
        if (!this.newReply) {
            this.showMessage('回复内容不能为空', 'error');
            return;
        }
        this.dialog = false;
        let formData = new FormData();
        formData.append('content', this.newReply);
        if (this.referencedReplyId) {
            formData.append('referencedReplyId', this.referencedReplyId);
        }
        service.post(`/wikiapi/forum/posts/${this.id}/replies`, formData)
            .then(() => {
                this.newReply = '';
                this.referencedReplyId = null;
                this.fetchPost();
                this.showMessage('回复帖子成功');
            })
            .catch(error => {
                this.showMessage(error.response.data.message, 'error');
            });
    },
    imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();
      input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const response = await service.post('/wikiapi/forum/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const range = this.$refs.editor.quill.getSelection();
        this.$refs.editor.quill.insertEmbed(range.index, 'image', response.data.url);
      };
    }
  }
};
</script>


<style scoped>
/* 通用样式 */
.pre-line {
  white-space: pre-line;
}

/* 帖子和回复之间的间距 */
.post, .reply {
  margin-bottom: 20px;
}

/* 卡片样式 */
.v-card {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 轻微阴影效果 */
}

/* 标题样式 */
.v-card-title {
  font-size: 1.25em;
  color: #424242;
}

/* 副标题样式 */
.v-card-subtitle {
  color: #757575;
}

/* 引用回复的样式 */
.referenced-reply-content {
  background-color: #f5f5f5; /* 背景色 */
  padding: 5px;
  border-left: 4px solid #1976D2; /* 左边框强调 */
  margin-bottom: 10px;
  font-size: 0.9em;
}

/* 回复编辑器对话框 */
.v-dialog {
  border-radius: 10px;
}

/* 编辑器标题 */
.v-dialog .v-card-title {
  color: #1976D2;
}

/* 文字对齐方式 */
.post-title, .reply-content {
  text-align: left;
}

/* 文字或背景颜色 */
.post-title {
  color: #333;
}

/* 文字大小 */
.post-content, .reply-content {
  font-size: 14px;
}

/* 回复按钮样式 */
.reply-btn {
  margin-top: 10px;
}

.referenced-reply-author {
  font-weight: bold;
  color: #555;
}

.referenced-reply-content {
  background-color: #f5f5f5;
  padding: 5px;
  border-left: 4px solid #1976D2;
  margin-bottom: 10px;
}

#post-content >>> p {
  margin: 0;
}
</style>

